
    import __i18nConfig from '@next-translate-root/i18n'
    import __loadNamespaces from 'next-translate/loadNamespaces'
    import { getStaticProps as __Next_Translate_old_getStaticProps__194a7200692__ } from "./[...slug]";
export { default } from "./[...slug]";

    async function __Next_Translate__getStaticProps__194a7200692__(ctx) {
      const res = await __Next_Translate_old_getStaticProps__194a7200692__(ctx)
      return {
        
        ...res,
        props: {
          ...(res.props || {}),
          ...(await __loadNamespaces({
            ...ctx,
            ...__i18nConfig,
            pathname: '/index',
            loaderName: 'getStaticProps',
            loadLocaleFrom: __i18nConfig && __i18nConfig.loadLocaleFrom || ((l, n) => import(`@next-translate-root/locales/${l}/${n}`).then(m => m.default)),
          }))
        }
      }
    }
    export { __Next_Translate__getStaticProps__194a7200692__ as getStaticProps }
  